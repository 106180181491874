.container-timers {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .add-client-form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .add-client-form input,select {
    padding: 10px;
    font-size: 16px;
    flex: 1;
    min-width: 100px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff;
  }
  
  .add-client-form button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #53c457;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    flex: 1;
    min-width: 100px;
  }
  
  .add-client-form button:hover {
    background-color: #45a049;
  }

  .text-temps{
    font-size: 13px;
    color: #515151;
  }
  
  .clients-container {
    margin-top: 20px;
  }
  
  /* Styles responsifs */
  @media (max-width: 768px) {
    .add-client-form {
        flex-direction: column;
        align-items: stretch;
    }
  
    .add-client-form input,
    .add-client-form button {
        width: 100%;
    }
  }
  