.login-container {
    margin-top: -140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5; /* Light gray background */
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .form-login{
    display: flex;
    flex-direction: column;
    width: 350px;
    padding: 2rem;
    border-radius: 5px;
    background-color: #fff; /* White form background */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .form-login
  .form-group {
    margin-bottom: 1rem;
  }
  .form-login
  label {
    color:#4e4e55;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  .form-login
  input[type="text"],
  input[type="password"] {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .form-login
  input[type="text"]:focus,
  input[type="password"]:focus {
   outline:none;
  }
  
  .form-login
  .btn-success {
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    background-color: #4CAF50; /* Green button color */
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .form-login
  .btn-danger {
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    background-color: #e6562a; /* Green button color */
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .form-login
  .btn-left{
    border-radius: 10px 0px 0px 10px;
    font-size: 12px;
  }
  .form-login
  .btn-midle{
    border-radius: 0px 0px 0px 0px;
    font-size: 12px;
  }
  .form-login
  .btn-right{
    border-radius: 0px 10px 10px 0px;
    font-size: 12px;
  }
  .form-login
  .btn-info {
    padding: 10px 20px;
    border: none;
 
    background-color: #3d8fec; /* Green button color */
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .form-login select{
    width: 101%;
  }
  .form-login
  .btn-i{
    padding: 10px 20px;
    border: none;
    font-size: 12px;
    border-radius: 5px;
    background-color: #3d8fec; /* Green button color */
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  


  .form-login
  .btn-danger:hover{
    background-color: #962323;
  }
  .form-login
  .btn-info:hover {
    background-color: #22548d;
  }

  .form-login
  .btn-success:hover {
    background-color: #3e8e41; /* Darker green on hover */
  }
  .form-login
  .btn-group{
    text-align: right;
  }
  .form-login
  .error-message {
    color: red;
    font-weight:bold;
    margin-bottom: 1rem;
    text-align: center;
  }

  @media(max-width:768px){
    form{
        width:90%;
    }
  }

.lien_compte{
  text-align: center;
  margin-top: 20px;
  text-decoration: none;
}