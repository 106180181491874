.header {
  background-color: #ffffff; /* Fond blanc */
  padding: 0.8rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Légère ombre */
  position: relative;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left{
  display: flex;
  flex-direction: row;
}

.header-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #7f7f7f;/* Texte gris foncé */
  padding-left:310px; /*slide on close*/
  
}

.header-tl-toggle-sleep {
  font-size: 1.2rem;
  font-weight: bold;
  color: #7f7f7f;/* Texte gris foncé */
  padding-left:10px; /*slide on close*/
  
}

.header-image {
  padding:5px;
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Cercle pour les images rondes */
  object-fit: cover; /* Ajuste l'image */
  border: 2px solid #ddd; /* Bordure fine autour de l'image */
  color:#7f7f7f;
}


.toggle-btn-header {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.6rem;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .header-title {
    font-size: 1.2rem; /********************/
    padding-left:0px; /*slide on close*/
  }

  .header-image {
    width: 40px;
    height: 40px;
  }
}


@media (max-width: 480px) {
  .header-title {
    font-size: 1.2rem; /********************/
    padding-left:0px; /*slide on close*/
    display: none;
  }

  .header-tl-toggle-sleep {
    font-size: 1.2rem;
    font-weight: bold;
    color: #7f7f7f;/* Texte gris foncé */
    padding-left:10px; /*slide on close*/
    display: none;
    
  }

  .toggle-btn-header{
    margin-bottom: 15px;
  }


}
