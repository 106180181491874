.users {
    padding: 1rem;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .users h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .btn-add {
    display: inline-block;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-add:hover {
    background-color: #45a049;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
  }
  
  .users-table th,
  .users-table td {
    padding: 1rem;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .users-table th {
    background-color: #f4f4f4;
  }
  
  .btn-edit,
  .btn-delete {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-edit {
    background-color: #2196f3;
    color: white;
    margin-right: 0.5rem;
  }
  
  .btn-edit:hover {
    background-color: #1e88e5;
  }
  
  .btn-delete {
    background-color: #f44336;
    color: white;
  }
  
  .btn-delete:hover {
    background-color: #e53935;
  }
  
  @media (max-width: 768px) {
    .users-table {
      font-size: 0.9rem;
    }
  
    .btn-edit,
    .btn-delete {
      font-size: 0.8rem;
      padding: 0.4rem 0.8rem;
    }
  }
  