.client-timer {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    text-align: center;
    background-color: #f8f9fa;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.time-display {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
}

.button-container {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.button {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.button:hover {
    opacity: 0.9;
}

.remove-button {
    background-color: #e57373;
    color: white;
}

.success-button{
    
    background-color: #53c457;;
    color: white;
   
}

.disabled{
    cursor: not-allowed;   /* Curseur qui indique que l'action est impossible */
     pointer-events: none;  /* Empêche toute interaction avec le bouton */
}

.para{
        flex-direction: row;
        display: flex;
        justify-content: center; /* Centre horizontalement */
        align-items: center; /* Centre verticalement */
      
      
}