.dashboard {
   margin-left:10%;
   margin-right: 10%;
    padding: 1rem;
  }
  
  .dashboard h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .dashboard-widgets {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .widget {
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #7f7f7f;/* Texte gris foncé */
  }
  
  .widget h2 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color:#829df5
  }
  
  .widget p {
    font-size: 1rem;
    text-align: left;
  }

  .button {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  