  /******************************************************/
  .dropdownProfile{
    position: absolute;
    top:4.5em;
    right:1.5rem;
    width:200px;
    padding:15px;
    border-radius: 8px;
    background-color: white;
    border: 1.5px solid #c3b6b6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2000;
  }

  .dropdownProfile a{
    text-decoration: none;
    color:#7f7f7f;
  }



  .dropdownProfile ul {
    list-style: none;
    padding: 0; /* Facultatif : supprime le padding par défaut */
    margin: 0; /* Facultatif : supprime la marge par défaut */
 
  }


  
  .dropdownProfile li{
    width: 90%;
    margin-top:10px;
    padding:5px 5px 5px 5px;
    border-radius: 2px;
    cursor: pointer;
    color:#7f7f7f;
  }
  .dropdownProfile li:hover{
    width: 90%;
    background-color: #e9e9e9;
    margin-top:10px;
    padding:5px 5px 5px 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .dropdownProfile::before{
    content:'';
    position: absolute;
    top: -0.7rem;
    right: 1.1rem;
    width: 20px;
    height: 20px;
    background-color: white;
    transform:rotate(45deg);
    border-left: 1.5px solid #c3b6b6;
    border-top: 1.5px solid #c3b6b6;
  }