/* Style du formulaire de transaction */
.transaction-form {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
}

.transaction-form select, 
.transaction-form input, 
.transaction-form button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.transaction-form button {
    background: #28a745;
    color: white;
    border: none;
    cursor: pointer;
}

.transaction-form input[type="number"]{
  width: 95%;
}
.transaction-form button:hover {
    background: #218838;
}
