/* UserProfile.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
  }
  
  .user-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .profile-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    padding: 20px;
    transition: transform 0.2s;
  }
  
  .profile-card:hover {
    transform: translateY(-5px);
  }
  
  .profile-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #3498db;
  }
  
  .user-name {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #333;
  }
  
  .user-email {
    font-size: 1rem;
    color: #777;
    margin: 5px 0;
  }
  
  .user-role {
    font-size: 1rem;
    color: #3498db;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .edit-button {
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .edit-button:hover {
    background-color: #2980b9;
  }


  @media (max-width: 768px) {
    .profile-card {
      width: 90%;
    }
  
    .profile-image img {
      width: 80px;
      height: 80px;
    }
  
    .user-name {
      font-size: 1.2rem;
    }
  
    .edit-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  
  .upload-button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 15px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .upload-button:hover {
    background-color: #2980b9;
  }
  

  @media (max-width: 768px) {
    .profile-card {
      width: 90%;
    }
  
    .profile-image img {
      width: 80px;
      height: 80px;
    }
  
    .user-name {
      font-size: 1.2rem;
    }
  
    .edit-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  

