.mainAdvanced{
    position: 'relative';
     width: 300px; 
     z-index: 2000;
}



.mainAdvanced input{
width: 100%; padding: 10px; border: 1px solid #ccc
}

.mainAdvanced  ul{
    position: absolute; top: 100%; left: 0px; width: 107%; border: 1px solid rgb(204, 204, 204); list-style: none; padding: 0px; margin: 0px; background-color: white; z-index: 1000;
}

@media (max-width: 570px) {


    .mainAdvanced input{
        width: 80%;
        margin-left: 10px;
    }

    .mainAdvanced  ul{
        width: 87%;
        margin-left:10px;
    }
  

  }