.settings {
    padding: 1rem;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .settings h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .settings-section {
    background: #f9f9f9;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .settings-section h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .settings-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .settings-form label {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }
  
  .settings-form input,
  .settings-form select {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn-save {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-save:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 600px) {
    .settings {
      padding: 0.5rem;
    }
  
    .settings-form label {
      font-size: 0.9rem;
    }
  
    .btn-save {
      padding: 0.6rem;
    }
  }
  