/* Conteneur principal */
.transaction-filter-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* Section des filtres */
.filter-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;
}

.filter-section label {
    font-weight: bold;
}

.filter-section select, 
.filter-section input, 
.filter-section button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
}

.filter-section button {
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
}

.filter-section button:hover {
    background-color: #0056b3;
}

/* Table des transactions */
.transaction-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.transaction-table th, 
.transaction-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
}

.transaction-table th {
    background-color: #007BFF;
    color: white;
}

.input-jours{
    width: 10%;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .filter-section {
        flex-direction: column;
        gap: 10px;
    }

    .transaction-table th, 
    .transaction-table td {
        padding: 8px;
        font-size: 14px;
    }
}
