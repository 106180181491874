body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .app {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .layout {
    display: flex;
    flex: 1;
    margin-top: 4rem; /* Pour compenser la hauteur du header */
  }
  
  .content {
    flex: 1;
    margin-left: 250px; /* Largeur de la sidebar */
    padding: 0rem;
  }

  .align-justify{
    text-align: justify;
  }

    
  .content-toggle {
    flex: 1;
  
    padding: 0rem;
  }
  
  @media screen and (max-width: 768px) {
    .layout {
      flex-direction: column;
    }
  
    .content {
      margin-left: 0;
    }
  }

  .label {
    display: inline-block;
    padding: 5px 5px;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    border-radius: 10px;
  }
  
  .label.classic {
    background-color: #3498db; /* Bleu */
  }
  
  .label.classic-np {
    background-color: #db4234; /* Bleu */
  }
  

  /* Clients.css : Style responsive pour le tableau */
.table-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  overflow-x: auto;
  background-color: none;
}


.table-container button{
  border-radius: 5px;
  margin-left: 5px;
  padding:3px;
  font-size: 12px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 10px;
}

th, td {
  padding: 12px;
  font-size: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background: #007bff;
  color: white;
  font-weight: bold;
}

tr:hover {
  background-color: #f1f1f1;
}
.table-btn{
background: #53c457;
border-radius: 10px 0 0 10px;
border: none;
padding: 5px;
color: #fff;
}

.table-btn-suppr{
  background: #db4234;
  border-radius: 0px 10px 10px 0px;
  border: none;
  padding: 5px;
  color: #fff;
  }
  

.add-client-form .btn-erreur{
  background: #db4234;
}

.add-client-form .btn-erreur:hover{
  background: #bf3f33;
}

/* styles.css */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.col-2 {
  flex: 0 0 20%;
  font-weight: bold;
}

.col-10 {
  flex: 0 0 80%;
}

/************** SPECIAL BOUTTON **********/
.btn-success{
  background-color: #007bff;
  color: white;
}

/********** SPECIAL MODAL *****************/


.body-modal{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}





@media (max-width: 768px) {
  th, td {
      padding: 8px;
      font-size: 14px;
  }

  .row {
    flex-direction: column;
}

.col-2, .col-10 {
    flex: 0 0 100%;
}
}



