/* Structure de la Sidebar */
.sidebar {
  width: 300px; /* Largeur ouverte */
  background-color: #f4f4f4;
  height: 100vh; /* Hauteur pleine */
  padding:1rem 0.2rem 0 1rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 0.3s ease; /* Animation fluide */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 3999;
}

.sidebar.closed {
  width: 80px; /* Largeur fermée */
  left: -400px;
}

.sidebarCorps{
  height: 90vh; /* Hauteur pleine */
  overflow-y: auto; /* Ajout du défilement vertical */
  overflow-x: hidden; /* Désactive le défilement horizontal */
  padding-right: 0px;
}


/* Personnalisation du Scroll */
.sidebarCorps::-webkit-scrollbar {
  width: 10px; /* Largeur de la barre de défilement */
}

.sidebarCorps::-webkit-scrollbar-track {
  background: #f1f1f1; /* Couleur de l'arrière-plan du track */
  border-radius: 10px; /* Coins arrondis */
}

.sidebarCorps::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #616161,  #a3a3a3); /* Gradient moderne */
  border-radius: 10px; /* Coins arrondis */
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2); /* Effet en relief */
}

.sidebarCorps::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #616161,  #a3a3a3); /* Couleur au survol */
}

/* Propriétés pour Firefox */
.sidebarCorps {
  scrollbar-width: thin; /* Mince pour Firefox */
  scrollbar-color:  #a3a3a3 #f1f1f1; /* Couleur du pouce et de la piste */
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 2rem 0;

}

.sidebar-menu li {
  margin-bottom: 1rem;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}


.sidebar-menu li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 0.8rem;
  justify-content:space-between;
  padding: 0.02rem 0.2rem;
  transition: color 0.3s ease;
}


.sidebar-menu li .sous-menu {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 0.8rem;
  justify-content:space-between;
  padding: 0.02rem 0.2rem;
  transition: color 0.3s ease;
}

.sidebar-menu li a:hover {
  color: #3498db;
}

.icon {
  font-size: 1.5rem;
  margin-right: 1rem;
}

/* Masque le texte dans l'état fermé */
.sidebar.closed .sidebar-menu li a span {
  display: none;
}




.toggle-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.toggle-btn:hover {
  background-color: #45a049;
}

/* Barre de recherche */
.search-box {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  width: 100%;
}

.search-icon {
  color: #888;
  margin-right: 0.5rem;
}

.search-input {
  border: none;
  outline: none;
  width: 100%;
}

.sidebar.closed .search-box {
  display: none; /* Masque la recherche si la barre est fermée */
}

.toggle-btn:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .sidebar {
    width:85%;
    position: fixed;
    z-index: 1000;
  }
}

a{
  cursor: pointer;
}
.container-flex {
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
}

.icon-x{
  font-size: 12px;
  padding-left: 12px;
  color: #a3a3a3;
}


@media (max-width: 768px) {
  .sidebar {
    top:100px;
    width:98%;
    position: fixed;
    z-index: 1000;
  }

  .sidebar-menu li .sous-menu{
    font-size: 0.8rem;
  }
}

