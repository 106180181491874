/* Style de la modale */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6); /* Fond semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
}

.modal-content h2 {
    margin-bottom: 20px;
}

.modal-content input, .modal-content button {
    width: 90%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.modal-content button {
    background: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
}

.modal-content button:hover {
    background: #0056b3;
}

.modal-content .cancel-button {
    background: red;
}
